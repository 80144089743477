export const getConfirmMessage = (state) => state.app.confirmMessage;

export const getDeviceType = (state) => state.app.deviceType;

export const getMenuNavigationOpened = (state) => state.app.isMenuNavigationOpened;

export const getMenuProfileOpened = (state) => state.app.isMenuProfileOpened;

export const getModalConnection = (state) => state.app.isModalConnection;

export const getImageViewerUrl = (state) => state.app.imageViewerUrl;

export const getDetails = (state) => state.app.details;

export const getModalUserProfile = (state) => state.app.modalUserProfile;

export const getModalReport = (state) => state.app.modalReport;

export const getPageLoading = (state) => state.app.isPageLoading;