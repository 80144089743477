// Import modules
import { useEffect, useCallback, memo } from "react";
import { useDispatch, useSelector } from "react-redux";

// Import store
import * as appActions from 'store/features/app';
import * as appSelectors from 'store/selectors/app';

// Import constants
import { DEVICE_TYPES, DEVICE_MAX_WIDTHS } from "constants/devices";



export default memo(function Redimension() {

  const dispatch = useDispatch();
  const deviceType = useSelector(appSelectors.getDeviceType);

  const handleWindowResize = useCallback(() => {
    let newType;
    const windowWidth = window.innerWidth;
    if (windowWidth <= DEVICE_MAX_WIDTHS.MOBILE) {
      newType = DEVICE_TYPES.MOBILE;
    } else if ((windowWidth > DEVICE_MAX_WIDTHS.MOBILE) && (windowWidth <= DEVICE_MAX_WIDTHS.TABLET)) {
      newType = DEVICE_TYPES.TABLET;
    } else if (windowWidth <= DEVICE_MAX_WIDTHS.LAPTOP) {
      newType = DEVICE_TYPES.LAPTOP;
    } else {
      newType = DEVICE_TYPES.SCREEN;
    }
    if (newType !== deviceType) {
      dispatch(appActions.setDeviceType(newType));
    }
  }, [dispatch, deviceType]);

  useEffect(() => {
    handleWindowResize();
  }, [handleWindowResize])

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [handleWindowResize]);

  return (
    <></>
  );
});
