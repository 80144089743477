import { memo } from 'react';
import BackgroundMain from 'components/Background/BackgroundMain/BackgroundMain';
import BackgroundApp from 'components/Background/BackgroundApp/BackgroundApp';

import './Background.scss';

export default memo(function Background() {
    return (
        <div id='background'>
            <BackgroundApp />
            <BackgroundMain />
        </div>
    );
});
