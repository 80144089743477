// Import modules
import { useEffect, useCallback, memo } from 'react';
import { useAccount } from 'hooks/useAccount';

// Import API
import { userAPI } from "api/users/UserAPI";


export default memo(function Refresh() {
    const { setAccount } = useAccount();

    const refreshAccount = useCallback(() => {
        userAPI.refresh().then((res) => {
            setAccount(res.data);
        }).catch((err) => {});
    }, [setAccount]);

    useEffect(() => {
        refreshAccount();
    }, [refreshAccount]);
});