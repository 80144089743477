// Import modules
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Import store
import * as userActions from 'store/features/user';
import * as friendsActions from 'store/features/friends';
import * as discussionsActions from 'store/features/discussions';
import * as notificationsActions from 'store/features/notifications';
import * as userSelectors from 'store/selectors/user';

// Import API
import { socketClient } from "api/config";



export function useAccount () {

    const dispatch  = useDispatch();

    const username = useSelector(userSelectors.getName);
    const accountId = useSelector(userSelectors.getAccountId);

    /** 
     * Save account in redux store
     * @param {Object} pData - Account data
     */
    const updateAccountStore = useCallback((pData) => {
        dispatch(userActions.setName(pData.username));
        dispatch(userActions.setXp(pData.xp));
        dispatch(userActions.setCreatedAt(pData.createdAt));
        dispatch(userActions.setRights(pData.rights));
        dispatch(userActions.setAccountId(pData.accountId));
    }, [dispatch]);

    /**
     * Save profile in redux store
     * @param {Object} pData - Profile data
     */
    const updateProfileStore = useCallback((pData) => {
        dispatch(userActions.setCountry(pData.country));
        dispatch(userActions.setGender(pData.gender));
        dispatch(userActions.setBio(pData.bio));
        dispatch(userActions.setGender(pData.gender));
    }, [dispatch]);

    /**
     * Save avatar in redux store
     * @param {Object} pData - Avatar data
     */
    const updateAvatarStore = useCallback((pData) => {
        dispatch(userActions.setAvatar(pData.avatar));
    }, [dispatch]);

    /**
     * Update discussions store
     * @param {Object} pData - Discussions data
     */
    const updateDiscussionsStore = useCallback((pData) => {
        if (pData.nbUnreadMessages > 0) {
            dispatch(discussionsActions.setNbMessagesNotRead(pData.nbUnreadMessages));
        }
    }, [dispatch]);

    /**
     * Update friends store
     * @param {Object} pData - Friends data
     */
    const updateFriendsStore = useCallback((pData) => {
        if (pData.nbUnreadFriendsRequests > 0) {
            dispatch(friendsActions.setNbFriendsRequestsNotRead(pData.nbUnreadFriendsRequests));
        }
    }, [dispatch]);

    /**
     * Update notification store
     * @param {Object} pData - Discussions data
     */
    const updateNotificationsStore = useCallback((pData) => {
        if (pData.nbUnreadNotifications > 0) {
            dispatch(notificationsActions.setNbNotifications(pData.nbUnreadNotifications));
        }
    }, [dispatch]);

    /**
     * Reset account in redux store
     */
    const resetAccountStore = useCallback(() => {
        dispatch(userActions.reset());
        dispatch(friendsActions.reset());
        dispatch(discussionsActions.reset());
        dispatch(notificationsActions.reset());
    }, [dispatch]);

    /**
     * Set account in redux store
     */
    const setAccountStore = useCallback((pData) => {
        const account = {
            username: pData.username,
            xp: pData.xp,
            createdAt: pData.createdAt,
            rights: pData.rights,
            accountId: pData.accountId
        }
        const profile = {
            country: pData.country,
            gender: pData.gender,
            bio: pData.bio,
            background: pData.background
        }
        const avatar = {
            avatar: pData.avatar
        }
        const discussions = {
            nbUnreadMessages: pData.nbUnreadMessages
        }
        const notifications = {
            nbUnreadNotifications: pData.nbUnreadNotifications
        }
        const friends = {
            nbUnreadFriendsRequests: pData.nbUnreadFriendsRequests
        }

        updateAccountStore(account);
        updateProfileStore(profile);
        updateAvatarStore(avatar);
        updateDiscussionsStore(discussions);
        updateNotificationsStore(notifications);
        updateFriendsStore(friends);
    }, [
        updateAccountStore, 
        updateProfileStore, 
        updateAvatarStore,
        updateDiscussionsStore,
        updateFriendsStore,
        updateNotificationsStore
    ]);

    /**
     * Save profile in ws api
     * @param {Object} pData - Profile data
     */
    const setProfile = useCallback((pData) => {
        const profile = {
            country: pData.country,
            gender: pData.gender,
            bio: pData.bio,
            background: pData.background
        }
        updateProfileStore(profile);
        socketClient.emit('accounts/update-profile', username, accountId);
    }, [username, accountId, updateProfileStore]);

    /**
     * Save account and profile in ws api
     * @param {string} pUsername - Username
     * @param {string} pAccountId - Account id
     * @param {Object} pData - Account and profile data
     */
    const setAccount = useCallback((pData) => {
        setAccountStore(pData)
        socketClient.emit('accounts/create', pData.username, pData.accountId);
    }, [setAccountStore]);

    /**
     * Save avatar in ws api
     * @param {Object} pAvatar - Avatar data
     */
    const setAvatar = useCallback((pAvatar) => {
        const avatar = {
            avatar: pAvatar
        }
        updateAvatarStore(avatar);
        socketClient.emit('accounts/update-profile', username, accountId);
    }, [username, accountId, updateAvatarStore]);    

    /**
     * Reset account in redux store
     */
    const resetAccount = useCallback(() => {
        resetAccountStore();
        socketClient.emit('accounts/delete');
    }, [resetAccountStore]);

    return { 
        setAccount: setAccount,
        setProfile: setProfile,
        setAvatar: setAvatar,
        resetAccount: resetAccount,
        setAccountStore: setAccountStore,
        resetAccountStore: resetAccountStore
    };
}