import { memo } from 'react';
import { FaGamepad, FaGlassMartiniAlt } from "react-icons/fa";
import { RiGameFill } from "react-icons/ri";
import {
  BiMusic,
  BiRocket,
  BiWinkTongue,
  BiTrophy,
  BiCycling,
  BiBaseball,
  BiCameraMovie
} from "react-icons/bi";
import { FiStar } from "react-icons/fi";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { getPageLoading } from "store/selectors/app";
import { useSelector } from "react-redux";

import "./BackgroundMain.scss";

const listIcons = [
  <FaGamepad />,
  <BiBaseball />,
  <BiWinkTongue />,
  <FiStar />,
  <BiCycling />,
  <BiRocket />,
  <BiMusic />,
  <FaGlassMartiniAlt />,
  <RiGameFill />,
  <BiCameraMovie />,
  <BiTrophy />
];

const ROUTE_POSITIONS = {
  '/': 'position-home',
  '/home': 'position-home'
}

export default memo(function BackgroundMain() {

  const location = useLocation();
  const [positionClass, setPositionClass] = useState(ROUTE_POSITIONS[location.pathname] || 'position-default');
  const isPageLoading = useSelector(getPageLoading);

  useEffect(() => {
    let newPositionClass = 'position-default';
    switch(true) {
      case isPageLoading:
        newPositionClass = 'position-loading';
        break;
      case ROUTE_POSITIONS[location.pathname] !== undefined:
        newPositionClass = ROUTE_POSITIONS[location.pathname];
        break;
      default:
        break;
    }

    if (newPositionClass !== positionClass) {
      setPositionClass(newPositionClass);
    }
  }, [isPageLoading, positionClass, location.pathname]);

  return (
    <div id="backgroundMain">
      <div className={["bg", positionClass].join(" ")}>
        <div className="area">
          {/* <ul>
            {listIcons.map((icon, index) => (
              <li key={index}>{icon}</li>
            ))}
          </ul> */}
        </div>
      </div>

      <div className="fg">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className={positionClass}>
          <path
            fill="#f9f3e9"
            fillOpacity="1"
            d="M0,256L80,229.3C160,203,320,149,480,160C640,171,800,245,960,240C1120,235,1280,149,1360,106.7L1440,64L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
          ></path>
        </svg>
      </div>
    </div>
  );
});
