// Import modules
import React, { Suspense, lazy } from 'react';
import { ToastContainer } from 'react-toastify';

// Import style
import 'bootstrap/dist/css/bootstrap.min.css';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

// Not lazy loading components
import LoadingPage from "components/LoadingPage/LoadingPage";
import Background from "components/Background/Background";
import Redimension from "components/AppEvents/Redimension";
import PageChanged from "components/AppEvents/PageChanged";
import Refresh from "components/AppEvents/Refresh";

// Lazy loading components
const Navigation          = lazy(() => import("./components/Navigation/Navigation"));
const NavBar              = lazy(() => import("./components/NavBar/NavBar"));
const Footer              = lazy(() => import("./components/Footer/Footer"));
const ModalFriends        = lazy(() => import("./components/ModalFriends/ModalFriends"));
const ModalDiscussions    = lazy(() => import("./components/ModalDiscussions/ModalDiscussions"));
const ModalNotifications  = lazy(() => import("./components/ModalNotifications/ModalNotifications"));
const Discussions         = lazy(() => import('./components/Discussions/Discussions'));
const DiscussionsTab      = lazy(() => import('./components/DiscussionsTab/DiscussionsTab'));
const ModalLanguage       = lazy(() => import('./components/ModalLanguage/ModalLanguage'));
const ModalConnection     = lazy(() => import("./components/ModalConnection/ModalConnection"));
const MenuNavigation      = lazy(() => import("./components/MenuNavigation/MenuNavigation"));
const MenuProfile         = lazy(() => import("./components/MenuProfile/MenuProfile"));
const ModalUserProfile    = lazy(() => import("./components/ModalUserProfile/ModalUserProfile"));
const ModalReport         = lazy(() => import("./components/ModalReport/ModalReport"));
const ModalConfirm        = lazy(() => import("./components/ModalConfirm/ModalConfirm"));
const AppListener         = lazy(() => import('./AppListener'));


export default function App () {

  return (
    <div className="app">
      <Background />
      <Suspense fallback={<LoadingPage />}>
        <Redimension />
        <PageChanged />
        <Refresh />
        <Navigation />
        <NavBar />
        <Footer />
        <ModalConnection />
        <ModalFriends />
        <ModalNotifications />
        <ModalDiscussions />
        <Discussions />
        <DiscussionsTab />
        <ModalLanguage />
        <ModalUserProfile />
        <ModalReport />
        <ModalConfirm />
        <MenuNavigation />
        <MenuProfile />
        <ToastContainer />
        <AppListener />
      </Suspense>
    </div>
  );
}
