import { createSlice } from '@reduxjs/toolkit'
 
const initialState_ = {}

const { actions, reducer } = createSlice({
    name: 'users',
    initialState: initialState_,
    reducers: {
        set: (state, action) => {
            state = action.payload
        },
        add: (state, action) => {
            state[action.payload.nickname] = {
                nickname : action.payload.nickname, 
                peer : action.payload.peer,
                username : action.payload.username,
                volume: action.payload.volume,
                avatarId: action.payload.avatarId,
                profile: {},
                isBlocker: false,
                isBlocked: false
            }
        },
        remove: (state, action) => {
            delete state[action.payload];
        },
        setVolume: (state, action) => {
            if (state[action.payload.nickname]) {
                state[action.payload.nickname].volume = action.payload.volume;
            }
        },
        setUsername: (state, action) => {
            if (state[action.payload.nickname]) {
                state[action.payload.nickname].username = action.payload.username;
            }
        },
        setProfile: (state, action) => {
            if (state[action.payload.nickname]) {
                state[action.payload.nickname].profile = action.payload.profile;
            }
        },
        setBlockage: (state, action) => {
            if (state[action.payload.nickname]) {
                state[action.payload.nickname].isBlocker = action.payload.isBlocker ?? false;
                state[action.payload.nickname].isBlocked = action.payload.isBlocked ?? false;
            }
        },
        reset: () => {
            return initialState_
        }
    },
});

export const { 
    set,
    add,
    remove,
    setVolume,
    setUsername,
    setProfile,
    setBlockage,
    reset
} = actions;

export default reducer;