import { useEffect, memo } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

export default memo(function PageChanged() {
    const { pathname } = useLocation();

    useEffect(() => {

        // Scroll to top of page
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant",
        });

        // Close all toasts
        toast.dismiss();
    }, [pathname]);

    return (
        <></>
    );
});
