// Import modules
import { createSlice } from '@reduxjs/toolkit';

// Import constants
import { DEVICE_TYPES, DEVICE_MAX_WIDTHS } from "constants/devices";
import { ORIGIN_MODAL_USER } from "constants/modals";
import { DEFAULT_AVATAR_ID } from "constants/avatar";

const getDeviceCategory = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth <= DEVICE_MAX_WIDTHS.MOBILE) {
        return DEVICE_TYPES.MOBILE;
    } else if ((windowWidth > DEVICE_MAX_WIDTHS.MOBILE) && (windowWidth <= DEVICE_MAX_WIDTHS.TABLET)) {
        return DEVICE_TYPES.TABLET;
    } else if (windowWidth <= DEVICE_MAX_WIDTHS.LAPTOP) {
        return DEVICE_TYPES.LAPTOP;
    } else {
        return DEVICE_TYPES.SCREEN;
    }
}

const DEFAULT_MODAL_USER = {
    isHidden: true,
    username: '',
    nickname: '',
    avatarId: DEFAULT_AVATAR_ID,
    origin: ORIGIN_MODAL_USER.DEFAULT,
    x: 'calc(100% - 312px)',
    y: '65px',
    isBackdrop: true
};

const DEFAULT_CONFIRM_MESSAGE = {
    action: '',
    text: '',
    data: {},
    confirmed: false
};

const DEFAULT_MODAL_REPORT = {
    username: '',
    origin: ''
};

const DEFAULT_MODAL_DETAILS = {
    title: '',
    content: ''
};

const { actions, reducer } = createSlice({
    name: 'app',
    initialState: { 
        confirmMessage: DEFAULT_CONFIRM_MESSAGE,
        deviceType: getDeviceCategory(),
        isMenuNavigationOpened: false,
        isMenuProfileOpened: false,
        isModalConnection: false,
        imageViewerUrl: '',
        details: DEFAULT_MODAL_DETAILS,
        modalUserProfile: DEFAULT_MODAL_USER,
        modalReport: DEFAULT_MODAL_REPORT,
        isPageLoading: true
    },
    reducers: {
        openConfirmMessage: (state, action) => {
            state.confirmMessage = {
                action: action.payload.action,
                text: action.payload.text, 
                data: action.payload.data, 
                confirmed: false
            };
        },
        acceptConfirmMessage: (state) => {
            state.confirmMessage = {
                action: state.confirmMessage.action,
                text: state.confirmMessage.text, 
                data: state.confirmMessage.data, 
                confirmed: true
            };
        },
        closeConfirmMessage: (state) => {
            state.confirmMessage = DEFAULT_CONFIRM_MESSAGE;
        },
        setDeviceType: (state, action) => {
            state.deviceType = action.payload;
        },
        setMenuNavigationOpened: (state, action) => {
            state.isMenuNavigationOpened = action.payload;
        },
        setMenuProfileOpened: (state, action) => {
            state.isMenuProfileOpened = action.payload;
        },
        setModalConnection: (state, action) => {
            state.isModalConnection = action.payload
        },
        setImageViewerUrl: (state, action) => {
            state.imageViewerUrl = action.payload;
        },
        setDetails: (state, action) => {
            state.details = action.payload;
        },
        showModalUserProfile: (state, action) => {
            state.modalUserProfile = action.payload;
        },
        hideModalUserProfile: (state, action) => {
            if (!state.modalUserProfile.isHidden) {
                state.modalUserProfile = DEFAULT_MODAL_USER;
            }
        },
        showModalReport: (state, action) => {
            state.modalReport = action.payload;
        },
        hideModalReport: (state, action) => {
            state.modalReport = DEFAULT_MODAL_REPORT;
        },
        setPageLoading: (state, action) => {
            state.isPageLoading = action.payload;
        }
    },
});

export const {
    openConfirmMessage,
    acceptConfirmMessage,
    refuseConfirmMessage,
    closeConfirmMessage,
    setDeviceType,
    setMenuNavigationOpened,
    setMenuProfileOpened,
    setModalConnection,
    setImageViewerUrl,
    setDetails,
    showModalUserProfile,
    hideModalUserProfile,
    showModalReport,
    hideModalReport,
    setPageLoading
} = actions;

export default reducer;