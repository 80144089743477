import { urlUsers, axiosInstance } from "api/config";

const URL_USER = urlUsers + '/user';

class UserAPI {
    constructor (instance, url) {
        this.instance = instance;
        this.url = url;
    }

    login (username, password, rememberMe) {
        return this.instance.post(this.url+'/login',{
            username: username,
            password: password,
            rememberMe: rememberMe
        });
    }

    loginWithGoogle (accessToken, rememberMe) {
        return this.instance.post(this.url+'/login/google', {
            access_token: accessToken,
            rememberMe: rememberMe
        });
    }

    edit (field, value) {
        return this.instance.put(this.url + '/', {
            field: field,
            value: value
        });
    }

    delete () {
        return this.instance.delete(this.url+'/delete');
    }

    refresh () {
        return this.instance.get(this.url+'/refresh');
    }
    
    logout () {
        return this.instance.get(this.url+'/logout');
    }

    forgetPassword (email) {
        return this.instance.post(this.url+'/forget-password', {
            email: email
        });
    }

    resetPassword (token, password) {
        return this.instance.post(this.url+'/reset-password', {
            token: token,
            password: password
        });
    }

    getToEdit () {
        return this.instance.get(this.url+'/get-to-edit');
    }

    get (username) {
        return this.instance.get(this.url, {
            params: { username: username }
        });
    }

    getAll () {
        return this.instance.get(this.url+'/all');
    }

    getMany (listUsername) {
        return this.instance.post(this.url+'/many', {
            listUsername: listUsername
        });
    }

    search (username) {
        return this.instance.get(this.url+'/search', {
            params: { username: username }
        });
    }
}

export const userAPI = new UserAPI(axiosInstance, URL_USER);
