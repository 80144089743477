import React, { memo } from 'react';
import './Spinner.scss';


export default memo(function Spinner ({ 
    type = 'default', 
    spinnerSize = 'large'
}) {

    return (
        <div id='spinner' className='center'>
            { 
                type === 'default' && 
                    <span className={"loader-default " + spinnerSize}></span>
            }
            { 
                type === 'avatar' && 
                <span className={"loader-default " + spinnerSize}></span>
            }
        </div>
    );
});